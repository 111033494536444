<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>

            <v-row justify="space-between">
              <v-col cols="auto">
                Aktuálny výber otázky pre nasledujúce NAŠE REFERENDUM
              </v-col>
              <v-col cols="auto">
                <v-row>
                  <v-col cols="auto">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <v-card><v-card-text>Tu môže pridať hocikto, teda aj Ty otázku, o ktorej budú rozhodovať
                          zaregistrovaní užívatelia tejto stránky či sa zaradí do nasledujúceho referenda. Vyber takú,
                          ktorá Ťa zaujíma a na ktorú si myslíš, že by bolo zaujímavé dozvedieť sa odpovede spoluobčanov.
                          Otázok môžeš pridať koľko chceš, avšak musia byť vhodné – uverejniteľné (prosíme nezahlcujte
                          systém hlúposťami). Po kontrole bude tvoja otázka zverejnená a potom už len čakáš na to, či
                          tvoja otázka získa záujem spoluobčanov.</v-card-text></v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="auto">
                    <div>
                      <fake-button
                        v-if="!$vuetify.breakpoint.smAndDown"
                        color="primary"
                        create
                        @click="dialog = isVerified"
                        text="Pridať moju novú otázku"
                      ></fake-button>
                      <fake-button
                        v-else
                        color="primary"
                        small
                        create
                        @click="dialog = isVerified"
                        text="Pridať moju novú otázku"
                      ></fake-button>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>
          <div v-if="questionChooses.length > 0">
            <v-card-text
              v-for="questionChoose in questionChooses"
              v-bind:key="questionChoose.id"
            >

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    top
                    dark
                    v-bind="attrs"
                    v-on="on"
                    size="16"
                    style="margin-bottom: 1.5px;"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <v-card><v-card-text> <span>Toto číslo slúži len ako informatívny údaj pre identifikáciu výberu otázok a
                      nemá žiadny vplyv na
                      hlasovanie.</span>
                  </v-card-text></v-card>
              </v-menu>

              <strong class="ma-1">Výber otázok číslo {{ questionChoose.id }}</strong>

              <v-card-title v-if="questionChoose.name">
                <v-row justify="space-between">
                  <v-col cols="10">
                    <v-tooltip
                      top
                      v-if="questionChoose.tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        > {{ questionChoose.name }}
                        </span>
                      </template>
                      <span>{{ questionChoose.tooltip }}</span>
                    </v-tooltip>
                    <span v-else>{{ questionChoose.name }}</span>
                  </v-col>
                  <v-col cols="1">
                    <share-component
                      :name="questionChoose.name"
                      :pageUrl="pageUrl"
                    ></share-component>
                  </v-col>
                </v-row>
              </v-card-title>

              <!-- <v-card-subtitle>Prebieha od {{ questionChoose.start_date | moment("DD.MM.YYYY HH:mm:ss") }} do {{
                  questionChoose.end_date | moment("DD.MM.YYYY HH:mm:ss") }}</v-card-subtitle> -->
              <v-card-subtitle>
                <countdown :time="getCountdownTime(questionChoose.end_date)">
                  <template slot-scope="props">Do konca zostáva / dní: <b>{{ props.days }}</b> / hodín: <b>{{ props.hours
                  }}</b> / minút: <b>{{ props.minutes
}}</b> / sekúnd: <b>{{ props.seconds }}</b>
                  </template>
                </countdown>
              </v-card-subtitle>
              <v-card-subtitle>
                <v-row
                  align="baseline"
                  justify="space-between"
                >
                  <v-col cols="auto">
                    <v-row>
                      <v-col cols="auto">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              color="primary"
                              style="margin-bottom: 1px;"
                              v-bind="attrs"
                              v-on="on"
                            >mdi-information-outline</v-icon>
                          </template>
                          <v-card><v-card-text>
                              <p>
                                Otázky sa zoraďujú defaultne (základné automatické predvolenie) tak, že vždy najnovšia
                                otázka je najvyššie. Vtedy tu nesvieti žiadna šípka. Otázky môžete zoradiť podľa počtu
                                hlasov a to vzostupne alebo zostupne – viď smer šípky. Zakliknutím výberu FAVORITA
                                (otázky, ktorá sa Ti páči) sa Ti po prekliku zobrazia len tie otázky, ktoré si si označil
                                ako FAVORIT. FAVORITOV si môžeš vybrať koľko chceš. Otázke, ktorá sa Ti najviac páči,
                                môžeš dať len jeden hlas. Tento hlas môžeš kedykoľvek meniť až do konca hlasovania.
                              </p>
                            </v-card-text></v-card>
                        </v-menu>

                        <v-btn
                          small
                          text
                          color="primary"
                          @click="changeSort(sort)"
                        >Zoradiť
                          podľa počtu hlasov <div v-show="sort != null">
                            <v-icon v-if="sort">mdi-arrow-down</v-icon><v-icon v-else>mdi-arrow-up</v-icon>
                          </div>
                        </v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn
                          v-if="isAuthenticated"
                          small
                          text
                          color="amber amber-darken-4"
                          @click="sortQuestionFavorite = !sortQuestionFavorite"
                        >Favoriti&nbsp;<v-icon v-if="sortQuestionFavorite">mdi-star</v-icon><v-icon v-else>mdi-star-outline</v-icon>
                        </v-btn>
                        <v-menu
                          offset-y
                          v-else
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="amber amber-darken-4"
                              small
                              text
                              v-bind="attrs"
                              v-on="on"
                            >Favoriti&nbsp;<v-icon v-if="sortQuestionFavorite">mdi-star</v-icon><v-icon v-else>mdi-star-outline</v-icon>
                            </v-btn>
                          </template>
                          <v-card><v-card-text> Nie si ZAREGISTROVANÝ/Á alebo PRIHLÁSENÝ/Á a preto nemôžeš využívať túto možnosť.
                              Ak si zaregistrovaný/á, tak sa prihlás a ak nie si ešte zaregistrovaný/á, plne sa zaregistruj a over svoju identitu. Potom môžeš využívať aj túto funkciu. Registrácia je možná <a href="/register">TU</a>.
                            </v-card-text></v-card>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-row align="baseline">
                      <v-col cols="auto">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class=""
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                            >mdi-information-outline</v-icon>
                          </template>
                          <v-card><v-card-text>
                              <p>
                                Ak si si nepoznačil nejakú otázku ako FAVORIT ani si za ňu nehlasoval a chceš ju nájsť a
                                pamätáš si z nej nejaké slovo, zadaj ho sem a systém Ti vyfiltruje len otázky, kde sa
                                nachádza toto slovo. Alebo si jednoducho zadávaj slová, ktoré Ťa zaujímajú a zistíš, či tu
                                je otázka s takýmto slovom. </p>
                            </v-card-text></v-card>
                        </v-menu>
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-model="filterText"
                          label="Vyhľadaj"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-subtitle>

              <v-card
                :class="getCardElevation(index)"
                v-for="(question, index) in getSortedFiltered(questionChoose.questions)"
                v-bind:key="question.id"
              >
                <div>
                  <v-card-title class="primary--text">
                    <v-row justify="space-between">
                      <v-col cols="10">
                        {{ question.text }}
                      </v-col>
                      <v-col
                        cols="1"
                        class="pr-2 d-flex justify-end align-start"
                      >
                        <v-tooltip bottom>
                          <template
                            v-if="isAuthenticated"
                            v-slot:activator="{ on, attrs }"
                          >
                            <v-icon
                              v-if="question.user_favorite"
                              color="amber amber-darken-4"
                              v-bind="attrs"
                              v-on="on"
                              @click="removeFavorite(question)"
                            >
                              mdi-star
                            </v-icon>
                            <v-icon
                              v-else
                              color="amber amber-darken-4"
                              v-bind="attrs"
                              v-on="on"
                              @click="addFavorite(question)"
                            >
                              mdi-star-outline
                            </v-icon>
                          </template>
                          <span>Favorit</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card-title>

                  <v-card-actions>
                    <v-row
                      style="word-break: normal;"
                      align="baseline"
                      justify="space-between"
                    >
                      <v-col
                        cols="12"
                        md="3"
                        class="mx-1"
                      >
                        <span v-if="question.user_first_name && question.user_location">Navrhovateľ: {{
                          question.user_first_name }}, {{ question.user_location }}</span>
                        <span v-else>Navrhovateľ neuvedený</span>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        class="mx-1"
                      >
                        Počet hlasov
                        <span class="text-h5">
                          {{ question.likes_count }}</span>
                      </v-col>

                      <v-col
                        cols="12"
                        md="3"
                        class="mx-1 d-flex justify-end align-end"
                      >
                        <div
                          v-if="isAuthenticated && isVerifiedSmsEmail"
                          :class="getMobileClasses"
                        >
                          <div v-if="question.can_like || question.user_liked || question.can_user_like">

                            <v-btn
                              v-if="!question.user_liked"
                              color="success"
                              @click="likeQuestion(question.id)"
                            >Dať
                              hlas</v-btn>
                            <v-btn
                              v-else
                              color="error"
                              @click="unlikeQuestion(question.id)"
                            >Odobrať hlas</v-btn>
                          </div>
                          <div v-else>
                            <v-btn
                              v-if="!question.user_liked"
                              color="success"
                              disabled
                            >Dať hlas</v-btn>
                          </div>
                        </div>
                        <fake-button
                          v-else
                          color="success"
                          text="Dať hlas"
                        ></fake-button>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </div>
              </v-card>
            </v-card-text>
          </div>
          <div v-else>
            <v-card-text>
              Momentálne nikto nepridal žiadnu otázku do výberu otázok pre Naše Referendá, <a @click="dialog = true">pridaj
                novú otázku</a>, ktorá môže byť zaradená do Nášho Referenda.
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                História všetkých výberov navrhovaných otázok do Našich Referend
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <data-table-paging-filtering
                  :headers="headersQuestionChooseArchive"
                  :get-items-func="getQuestionChooseArchive"
                  :item-row-background-func="itemRowBackgroundFunc"
                  :show-expand="true"
                  :dense="false"
                  :sync="syncQuestionChooseArchive"
                  uniqueID="polls-questionchoosearchive"
                >
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-card
                        class="ma-1"
                        v-for="question in item.questions"
                        v-bind:key="question.id"
                      >
                        <v-card-title class="primary--text text-h3">
                          {{ question.text }}</v-card-title>

                        <v-card-actions v-if="isVerified">
                          <v-row>
                            <v-col cols="4">
                              <span v-if="question.user_first_name && question.user_location">Navrhovateľ: {{
                                question.user_first_name }}, {{ question.user_location }}</span>
                              <span v-else>Navrhovateľ neuvedený</span>
                            </v-col>
                            <v-col cols="4">
                              <strong>
                                {{ question.likes_count }} hlasov</strong>
                            </v-col>
                          </v-row>
                        </v-card-actions>
                      </v-card>
                    </td>
                  </template>

                  <template v-slot:item.start_date="{ item }">
                    {{ item.start_date | moment("DD.MM.YYYY HH:mm:ss") }}
                  </template>

                  <template v-slot:item.end_date="{ item }">
                    {{ item.end_date | moment("DD.MM.YYYY HH:mm:ss") }}
                  </template>

                </data-table-paging-filtering>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="isAuthenticated">
              <v-expansion-panel-header>
                História všetkých mojich otázok
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <data-table-paging-filtering
                  :headers="headersMyQuestions"
                  :get-items-func="getMyQuestions"
                  :item-row-background-func="itemRowBackgroundFunc"
                  :show-expand="true"
                  :dense="false"
                  :sync="syncMyQuestions"
                  uniqueID="polls-myquestions"
                >

                  <template v-slot:item.status="{ item }">
                    <v-chip :color="getStatus(item.status)[1]">
                      {{ getStatus(item.status)[0] }}
                    </v-chip>
                  </template>

                  <template v-slot:item.created_at="{ item }">
                    {{ item.created_at | moment("DD.MM.YYYY HH:mm:ss") }}
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-card>
                        <v-card-title>Znenie otázky</v-card-title>
                        <v-card-text>{{ item.text }}</v-card-text>
                      </v-card>
                    </td>
                  </template>

                </data-table-paging-filtering>
              </v-expansion-panel-content>
            </v-expansion-panel>

          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Nová otázka do Nášho Referenda</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              light
              color="success"
              rounded
              class="pa-5"
              @click="createQuestion(newQuestion)"
            >Odoslať otázku<br />na
              schválenie</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Navrhované znenie otázky</v-list-item-title>
              <v-list-item-subtitle>
                <v-textarea
                  label="Sem pridaj znenie tvojej navrhovanej otázky"
                  rows="1"
                  auto-grow
                  v-model="newQuestion.text"
                ></v-textarea>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-checkbox
                  v-model="newQuestion.anonymous"
                  label="Chcem aby moja otázka bola anonymná (nezobrazí sa pri nej navrhovateľ)"
                ></v-checkbox>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="!newQuestion.anonymous">
            <v-list-item-content>
              <v-list-item-title>Meno</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="newQuestion.user_first_name"></v-text-field>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="!newQuestion.anonymous">
            <v-list-item-content>
              <v-list-item-title>Mesto/Obec</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="newQuestion.user_location"></v-text-field>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-alert type="warning">Tvoja otázka bude zverejnená po kontrole jej znenia administrátorom. V prípade, že
          administrátor vyhodnotí tvoju otázku ako nevhodnú, nebude zaradená medzi potenciálne otázky Nášho
          Referenda.</v-alert>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
import Vue from "vue";

import { APIService } from "@/http/APIService.js";
import { mapGetters } from "vuex";

import DataTablePagingFiltering from "@/components/DataTablePagingFiltering.vue";
import FakeButton from "@/components/FakeButton.vue";

import moment from 'moment';
import VueCountdown from '@chenfengyuan/vue-countdown';
import ShareComponent from "../components/ShareComponent.vue";

Vue.component(VueCountdown.name, VueCountdown);

const apiService = new APIService();

Vue.use(require("vue-moment"));

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

export default {
  components: {
    "data-table-paging-filtering": DataTablePagingFiltering,
    "fake-button": FakeButton,
    ShareComponent
  },

  data: () => ({
    loading: false,
    sort: null,
    referendums: [],
    questionChooses: [],
    myQuestions: [],
    syncMyQuestions: false,
    syncReferendumArchive: false,
    syncQuestionChooseArchive: false,
    sortQuestionFavorite: false,
    user: {},
    filterText: null,

    newQuestion: {
      text: "",
      user_first_name: "",
      user_location: "",
      user: 1,
      anonymous: false
    },

    dialog: false,

    headersMyQuestions: [
      {
        text: "ID",
        value: "id",
      },
      { text: "Vytvorené", value: "created_at" },
      { text: "Počet hlasov vo výbere", value: "likes_count" },
      { text: "Počet ÁNO hlasov v NR ", value: "votes_yes_count" },
      { text: "Počet NIE hlasov v NR ", value: "votes_no_count" },

      { text: "Status", value: "status", width: "15%" }
    ],


    headersQuestionChooseArchive: [
      {
        text: "ID",
        value: "id",
      },
      {
        text: "Názov",
        value: "name"
      },
      { text: "Začiatok", value: "start_date" },
      { text: "Koniec", value: "end_date" },
    ],
  }),

  mounted: function () {
    this.getMe();
    this.getQuestionChooses();
  },

  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "username",
      "isVerified",
      "isVerifiedBank",
      "isVerifiedSmsEmail",
      "isStaff",
      "getMemberStatus",
      "firstName",
      "lastName",
      "gender"
    ]),

    getMobileClasses() {
      if (this.$vuetify.breakpoint.smAndDown)
        return "d-flex justify-center align-center";
      else
        return ""
    },

    pageUrl() {
      return encodeURI(window.location.origin + this.$route.path);
    },
  },

  methods: {
    changeSort(sort) {
      if (this.sort == null)
        this.sort = true;
      else if (this.sort == true)
        this.sort = false;
      else if (this.sort == false)
        this.sort = null;
    },
    getSortedFiltered(questions0) {
      let result = JSON.parse(JSON.stringify(questions0));

      if (this.sort != null)
        this.sortQuestionChoose(result, this.sort);

      if (this.sortQuestionFavorite)
        result = result.filter(question => question.user_favorite);


      if (this.filterText) {
        result = result.filter(question => question.text.toUpperCase().normalize("NFKC").includes(this.filterText.toUpperCase().normalize("NFKC")));
      }

      return result;
    },

    sortQuestionChoose(items, sort) {
      if (sort) {
        items.sort(function (a, b) {
          return a.likes_count - b.likes_count;
        });
      }
      else {
        items.sort(function (a, b) {
          return b.likes_count - a.likes_count;
        });
      }
    },

    getCountdownTime(date) {
      return moment(date).valueOf() - Date.now();
    },
    addFavorite(item) {
      this.loading = true;
      apiService.addFavoriteReferendumQuestion(item.id).then(() => {
        this.loading = false;
        this.getQuestionChooses();
      });
    },

    removeFavorite(item) {
      this.loading = true;
      apiService.removeFavoriteReferendumQuestion(item.id).then(() => {
        this.loading = false;
        this.getQuestionChooses();
      });
    },

    getCardElevation(index) {
      let classes = "mb-3";
      // if (index % 2 == 1) {
      //   classes += " elevation-1";
      // }

      return classes;
    },

    getMe() {
      apiService.getMe().then((response) => {
        this.user = response;
        this.newQuestion.user_first_name = this.user.first_name;
        this.newQuestion.user_location = this.user.address_town;
      });
    },

    getStatus(status) {
      switch (status) {
        case 'NEW': return ["Nová", "grey"]
        case 'APPROVED': return ["Schválená", "success"]
        case 'REJECTED': return ["Neschválená", "error"]
        default: return ["Žiadny", "warning"]
      }
    },

    itemRowBackgroundFunc() {
      return "";
    },

    likeQuestion(id) {
      apiService.likeReferendumQuestion(id).then(() => {
        this.getQuestionChooses();
      })
    },

    unlikeQuestion(id) {
      apiService.unlikeReferendumQuestion(id).then(() => {
        this.getQuestionChooses();
      })
    },

    getMyQuestions(sort, search, limit, offset, filters) {
      return apiService.getReferendumQuestionMy(sort, search, limit, offset, filters);
    },


    getQuestionChooseArchive(sort, search, limit, offset, filters) {
      return apiService.getQuestionChooseArchive(sort, search, limit, offset, filters);
    },


    getQuestionChooses() {
      apiService.getQuestionChooseSimple().then((response) => {
        this.questionChooses = response.results;
      });
    },

    createQuestion(newItem) {
      if (this.newQuestion.text === null || this.newQuestion.text.trim() === "") {
        this.dialog = !this.dialog;
        this.newQuestion.text = "";
        this.newQuestion.user_first_name = this.user.first_name;
        this.newQuestion.user_location = this.user.address_town;

        return;
      }

      if (newItem.anonymous) {
        this.newQuestion.user_first_name = null;
        this.newQuestion.user_location = null;
      }
      apiService.createReferendumQuestion(newItem).then(() => {
        this.syncMyQuestions = !this.syncMyQuestions;
        this.dialog = !this.dialog;
        this.newQuestion.text = "";
      })
    }
  },
};
</script>